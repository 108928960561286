
        @import "@/styles/griddle-overrides.scss";
        @import "@braid/griddle/scss/griddle.scss";
        





































































@import '@/styles/_colors.scss';

.widget {
  align-self: start;
  width: 100%;
  margin-bottom: 0.5rem;
  background: white;
  border: 1px solid $color-grey-100;
}

.widget__header {
  position: relative;
  display: flex;
  align-items: baseline;
  padding: 0.5rem;

  @media screen and (min-width: bp(m)) {
    padding: 1rem 1rem 0.75rem;
  }
}

.widget__title {
  font-weight: 500;
}

.widget__toggle {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: auto;
  margin-left: auto;
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

.widget__icon {
  margin-right: 0.25rem;
}

.widget__body {
  padding: 0.5rem;

  @media screen and (min-width: bp(m)) {
    padding: 0 1rem 1rem;
  }
}
